import React from 'react'
import { Link } from 'gatsby'
import * as imagesHome from 'images/home'
import { peter_van_soest } from 'images'
import imagesAccountants from 'images/accountants'
import { moneybird, exact, twinfield, yuki_alt } from 'images/koppelingen'
import { steps } from 'images/product/salaris'
import images from 'images'
import FaqDropdown from 'components/faq-dropdown'
import Steps from 'components/steps'
import Helmet from 'react-helmet'
import Layout from '../../layouts'

import { window } from 'browser-monads'

import config from '../../config'
const { appUrl, signupAccountantRoute } = config;

const {
  homeHero,
  logos: {
    woodwatch,
    petossi,
    storageShare,
    codaisseur,
  },
  illus: {
    eenvoudig,
    hrzaken,
    verlof,
    koppelingen,
    bespaar,
    salaris,
    hr,
  },
} = imagesHome

const { heroAccountants, eenvoudig0, eenvoudig1, eenvoudig2, } = imagesAccountants
// const { pricingCheck } = images

const questions = [
  {
    title: 'Betaal ik € 25,- per klant of voor het kantoor?',
    id: 'hoe-lang-proefperiode',
    content: 'Je betaalt eenmalig € 25 per maand, ongeacht het aantal administraties dat je hebt. Daarnaast betaal je 5 euro per loonstrook. Daarboven op ontvang je een factuurkorting die wordt berekend over het gehele bedrag op je factuur.',
    link: ''
  }, {
    title: 'Kunnen mijn klanten ook inloggen?',
    id: 'employes-toegang',
    content: 'Ja, dat is ook mogelijk. Zowel de ondernemer als de werknemers kunnen inloggen bij Employes. Dit is inbegrepen in de tarieven.',
    link: ''
  },{
    title: 'Is Employes een volledig pakket?',
    id: 'volledig-pakket',
    content: 'Absoluut. De software van Employes is te gebruiken als volwaardig salarispakket en bevat, zowel qua functionaliteiten als regelgeving, alles wat nodig is voor het doen van de salarisadministratie',
    link: ''
  },  {
    title: 'Welke CAO\'s ondersteunt Employes?',
    id: 'employes-cao',
    content: 'Employes ondersteunt nagenoeg alle CAO\'s. Hieronder vind je een uitgebreid overzicht.',
    link: 'https://support.employes.nl/nl/articles/3689950-welke-cao-s-ondersteunt-employes'
  }
]

class AccountantsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }
    this.setActive = this.setActive.bind(this)

  }

  setActive(event) {
    const id = event.target.id === this.state.activeID ? '' : event.target.id

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }


  render() {
    return(
      <Layout location={this.props.location}>
        <main className="home accountants animated fadeInPage">

            <Helmet>
              <title>Moderne salarisadministratie voor accountants | Employes</title>
              <meta name="description" content="Salarisadministratie voor accountants. Meerwaarde voor je klanten, extra omzet voor jou. Probeer nu gratis!" />
              <meta itemprop="name" content="Moderne salarisadministratie voor accountants | Employes" />
              <meta itemprop="description" content="Salarisadministratie voor accountants. Meerwaarde voor je klanten, extra omzet voor jou. Probeer nu gratis!"/>
              <meta itemprop="image" content="/static/meta-img.png" />
            </Helmet>

          <header className="header padding-xl-top padding-xl-bottom">
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-7 header-text">
                  <div>
                    <p className="eyebrow">Employes voor accountants</p>
                    <h1>Moderne online salaris&shy;administratie voor accountants</h1>
                    <p className="streamer margin-m-bottom">Gebruiksvriendelijke online salarisadministratie voor zowel werkgevers als acccountants.</p>
                    <a className="btn primary lg margin-xs-bottom" href={ appUrl + signupAccountantRoute } rel="nofollow">Probeer nu gratis</a>
                    <br />
                    <p className="small sky-dark hide-mobile">Je kunt Employes vrijblijvend en gratis testen</p>
                  </div>
                </div>

                <div className="hero-illustration">
                  <img src={heroAccountants} alt="Salarisadministratie voor accountants"/>
                </div>
              </div>
            </div>

            <div className="logo-grid padding-xl-top hide-mobile block">
              <div className="container-sm margin-s-bottom">
                <div className="grid yg align-middle logos">

                  {[moneybird, exact, twinfield, yuki_alt].map((img, idx) => (
                    <div className="col-3 align-middle  no-mar" key={idx}>
                      <div className={`logo ${idx === 0 ? 'moneybird' : idx === 1 ? 'exact' : idx === 2 ? 'twinfield' : idx === 3 ? 'yuki' : ''}`}>
                        <img src={img} alt="logos boekhoudpakketten"/>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
            <div className="container-sm hide-mobile block">
              <div className="grid center text-center yg align-middle">
                <p className="hide-mobile">Je kunt Employes koppelen aan verschillende boekhoudprogramma's.</p>
              </div>
            </div>
          </header>

          <section className="why overview padding-xxl">
            <div className="container-md">
              <div className="grid center text-center yg">
                <div className="col-8 section-header margin-xl-bottom">
                  <p className="eyebrow">Innovatieve software voor accountants</p>
                  <h2>Een nieuwe manier van salarisverwerking</h2>
                  <p>Employes is een volwaardig online salarispakket dat de manier van salarisverwerking totaal verandert. Dankzij de gebruiksvriendelijke en intuïtieve interface kun je eenvoudig gegevens invoeren. Het resultaat: je werkt veel efficiënter en bespaart tijd. </p>
                </div>

                <div className="grid between features">
                  <div className="col-4 feature">
                    <div className="feature-icon">
                      <img src={bespaar} alt="illustratie klok"/>
                    </div>
                    <h5>Koppel met boekhoudpakket</h5>
                    <p>Je koppelt Employes aan alle bekende boekhoudpaketten zoals Moneybird, Twinfield, Exact, Yuki, e-Boekhouden.nl, Informer en Snelstart en exporteert met één klik journaalposten.</p>
                    <br />
                    <p>Gebruikers zien altijd en overal een actuele boekhouding en ervaren meer inzicht.</p>
                  </div>
                  <div className="col-4 feature">
                    <div className="feature-icon">
                      <img src={eenvoudig} alt="illustratie hand"/>
                    </div>
                    <h5>Gebruiksgemak</h5>
                    <p>Dankzij Employes hoef je geen informatie over te typen en reduceer je handmatig werk. Kortom: meer tijd om jouw klanten een advies op maat te geven.</p>
                    <br />
                    <p>Gebruikers profiteren van een handige applicatie waarin zij hun werknemers toegang kunnen geven.</p>
                  </div>
                  <div className="col-4 feature">
                    <div className="feature-icon">
                      <img src={hrzaken} alt="illustratie support"/>
                    </div>
                    <h5>Uitgebreide support</h5>
                    <p>Accountants krijgen bij Employes een vaste contactpersoon. Die ondersteunt jou bij de overstap en geeft advies wanneer nodig. </p>
                    <br />
                    <p>Gebruikers kunnen direct contact opnemen met ons als er vragen zijn over de tooling. Maar jij blijft het vaste aanspreekpunt.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pricing padding-xxl-bottom">
            <div className="container-md">
              <div className="backdrop"></div>

              <div className="grid yg align-middle">
                <div className="col-8">
                  <p className="eyebrow">Tarieven voor accountants</p>
                  <h2>Tarieven </h2>
                  <p className="margin-l-bottom">Employes kost slechts € 25,- per maand waarvoor je een <strong>onbeperkt aantal administraties</strong> kunt toevoegen. Daarnaast betaal je € 5,- per loonstrook voor de basisfuncties. Voor slechts € 1,- extra per werknemer krijgt de werkgever volledige toegang tot de HR-functies. En hoe meer organisaties je verloont, hoe meer korting je ontvangt.</p>

                </div>
              </div>

              <div className="grid yg margin-s-bottom">
                <div className="col-3">
                  <div className="pricing-card basis">
                    <div className="top">
                      <h1>25
                        <small>/maand</small>
                      </h1>
                      <p className="small">+ &euro;5 per loonstrook per maand</p>
                    </div>

                    <div className="bottom">
                      <h4>Basis</h4>
                      <h6 className="no-margin">1 - 2 klanten</h6>
                    </div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="pricing-card vijf blue">
                    <div className="top">
                      <img src={eenvoudig0} alt="prijskaartjes"/>
                    </div>

                    <div className="bottom">
                      <h4>10% korting</h4>
                      <h6 className="no-margin">3 - 9 klanten</h6>
                    </div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="pricing-card vijftien pink">
                    <div className="top">
                      <img src={eenvoudig1} alt="prijskaartjes"/>
                    </div>

                    <div className="bottom">
                      <h4>15% korting</h4>
                      <h6 className="no-margin">10 - 24 klanten</h6>
                    </div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="pricing-card twinting yellow">
                    <div className="top">
                      <img src={eenvoudig2} alt="prijskaartjes"/>
                    </div>
                    <div className="bottom">
                      <h4>20% korting</h4>
                      <h6 className="no-margin"> &gt; 25 klanten</h6>
                    </div>
                  </div>
                </div>




          </div>

              <div className="grid center text-center streamer margin-m-bottom">
                <div className="col-12">
                  <p>Je ontvangt korting op de gehele factuur, dus zowel op het vaste bedrag als het bedrag per loonstrook.</p>
                </div>
              </div>

              <div className="background-container">
                <div className="grid yg center text-center">
                  <div className="col-12">
                    <div>
                      <a className="btn primary lg margin-xs-bottom" href={ appUrl + signupAccountantRoute } rel="nofollow">Probeer nu gratis</a>
                    </div>
                    <p className="eyebrow lower hide-mobile margin-l-bottom">Ontdek vrijblijvend het gemak van Employes</p>
                    <h3 className="margin-m-bottom">Alle voordelen en extra's</h3>
                  </div>
                </div>

                <div className="container-sm">
                  <div className="grid yg">
                    <div className="col-4">
                      <div className="feature margin-l-bottom">
                        <div className="checkmark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" className="small">
                            <polyline fill="none" stroke="#2C4BA9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="17.532 8 10.02 15.512 7 12.492" transform="translate(-6 -7)" />
                          </svg>
                        </div>
                        <div>
                          <h5 className="no-margin">Een vaste prijs</h5>
                          <p className="small">Voor € 25,- een onbeperkt aantal administraties</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="feature margin-l-bottom">
                        <div className="checkmark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" className="small">
                            <polyline fill="none" stroke="#2C4BA9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="17.532 8 10.02 15.512 7 12.492" transform="translate(-6 -7)" />
                          </svg>
                        </div>
                        <div>
                          <h5 className="no-margin">HR-functies</h5>
                          <p className="small">Voor € 1,- extra per werknemer toegang tot HR-functies.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="feature margin-l-bottom">
                        <div className="checkmark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" className="small">
                            <polyline fill="none" stroke="#2C4BA9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="17.532 8 10.02 15.512 7 12.492" transform="translate(-6 -7)" />
                          </svg>
                        </div>
                        <div>
                          <h5 className="no-margin">Factuur & betaling</h5>
                          <p className="small">Via jouw kantoor of de gebruiker</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="feature margin-l-bottom">
                        <div className="checkmark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" className="small">
                            <polyline fill="none" stroke="#2C4BA9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="17.532 8 10.02 15.512 7 12.492" transform="translate(-6 -7)" />
                          </svg>
                        </div>
                        <div>
                          <h5 className="no-margin">Directe loonaangifte</h5>
                          <p className="small">Integratie met de Belastingdienst</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="feature margin-l-bottom">
                        <div className="checkmark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" className="small">
                            <polyline fill="none" stroke="#2C4BA9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="17.532 8 10.02 15.512 7 12.492" transform="translate(-6 -7)" />
                          </svg>
                        </div>
                        <div>
                          <h5 className="no-margin">Journaalposten</h5>
                          <p className="small">Exporteren naar boekhouding</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="feature margin-l-bottom">
                        <div className="checkmark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" className="small">
                            <polyline fill="none" stroke="#2C4BA9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="17.532 8 10.02 15.512 7 12.492" transform="translate(-6 -7)" />
                          </svg>
                        </div>
                        <div>
                          <h5 className="no-margin">24/7 support</h5>
                          <p className="small">Advies op maat van onze specialisten</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        {/*
          <section className="faq padding-xxl-bottom">
            <div className="container-md">
              <div className="grid">
                <div className="col-5">
                  <p className="eyebrow">Overstappen</p>
                  <h2>Veelgestelde vragen</h2>
                  <p className="margin-m-bottom">Misschien gebruik jij of je klant al een salarisadministratie-applicatie en denk je dat de overstap naar Employes een hoop werk met zich meebrengt. Het tegendeel is waar: wij kunnen op korte termijn alles voor je regelen. Dit roept misschien vragen bij je op. Daarom zetten wij de veelgestelde vragen en antwoorden voor je op een rij.</p>
                  <Link to="/hulp/faq/" className="btn secondary lg">Lees meer</Link>
                </div>

                <div className="col-6 right">
                  <div className="faq-block">
                    {questions.map((question, qdx) => (
                      <FaqDropdown
                        question={question}
                        idx={qdx}
                        setActive={this.setActive}
                        activeID={this.state.activeID}
                        key={qdx}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          */}

          <div className="container-md padding-xxl">
            <div className="grid">
              <div className="col-6">
                <h6 className="eyebrow">Vraag en antwoord</h6>
                <h2>Veelgestelde vragen van accountants</h2>
                <p>
                  Employes kan voor jou een uitkomst zijn. Heb je nog vragen? Kijk dan eens bij de veelgestelde vragen en antwoorden van accountants.
                </p>
              </div>

              <div className="col-6 faq-block">
                {questions.map((question, idx) => (
                  <FaqDropdown
                    question={question}
                    idx={idx}
                    setActive={this.setActive}
                    activeID={this.state.activeID}
                    key={idx}
                  />
                ))}
              </div>
            </div>
          </div>

{/*
            <section className="client-card-large-container padding-l hide-client-card-mobile">
              <div className="container-md">
                <div className="client-card-large">
                  <div className="grid no-margin">

                    <div className="col-4 no-pad">
                      <div className="client-card-large-image">
                      <img src={peter_van_soest} alt="Klantverhaal salarisadministratie Peter van Soest"/>
                      </div>
                    </div>

                    <div className="col-8">
                      <div className="client-card-large-content padding-m">
                        <div className="grid yg">
                          <div className="col-12">
                            <p className="eyebrow">Use case administratiekantoor</p>
                            <h2>Peter, De Vrolijke Boekhouder</h2>
                            <p className="streamer large-x margin-s-bottom">Peter van Soest beheert de salarisadministratie voor zijn klanten met de software van Employes.</p>
                          </div>

                          <div className="col-6">
                            <p>Het pakket waarmee ik werk moet geen zorg erbij worden, maar me juist ontlasten en dat doet Employes. Het heeft een mooie en toegankelijke interface waar je snel mee aan de slag kunt en eenvoudig verloningen kunt doen. Met een paar kliks is de boel geregeld. Het verlonen van werknemers met variabele uren is vele malen eenvoudiger dan wat ik gewend was in mijn oude pakket.</p>
                          </div>
                          <div className="col-6">
                            <p>Ik werkte met een offline pakket waar op zich alles mee kon, maar waar afwijkende verloningen een crime was om te regelen. Vragen kon je alleen stellen via een ticketsysteem en dan maar hopen dat je op tijd een nuttig antwoord kreeg. Bij Employes krijg ik vlot antwoord per mail, chat of telefonisch. Als er wat aangepast moet worden in instellingen of percentages, doen ze dat meteen voor je.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
*/}

        </main>
      </Layout>
    )
  }
}

export default AccountantsPage
