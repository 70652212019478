import heroAccountants from './hero-accountants.svg'
import eenvoudig0 from './illu/eenvoudig.svg'
import eenvoudig1 from './illu/eenvoudig2.svg'
import eenvoudig2 from './illu/eenvoudig3.svg'

export default {
  heroAccountants,
  eenvoudig0,
  eenvoudig1,
  eenvoudig2
}
